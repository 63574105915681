import React from 'react';
import Layout from '../components/layout/Layout';
import {Helmet} from "react-helmet";

export default () => (
		<Layout>
			<Helmet>
				<meta charSet="utf-8"/>
				<title> Termin vorgemerkt </title>
			</Helmet>
			<section className="pt-20 md:pt-20">
				<h1 className="text-black bg-red-700 font-extrabold p-4">
					Wir haben im Moment technische Probleme.<br/>
					Falls du in den nächsten 5 Minuten keine Bestätigungsmail bekommst.<br/>
					Melde dich bitte beim support: info@recycular.com<br/>
				</h1>
				<div className="container mx-auto px-8 lg:flex">
					<div className="text-center lg:text-left lg:w-1/2">
						<h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-blue-500 leading-none">
							Danke, dass du dich für re.cycular entschieden hast.
						</h1>
						<br></br>
						<p className="text-3xl lg:text-xl font-semibold text-left">Nächste Schritte</p>
						<p className="mt-4">
							1. Im E-Mail Postfach findest du die Bestätigungsmail für deinen vorgemerkten Wunschtermin.
						</p>
						<p className="mt-4">
							2. In Kürze erhältst du eine weitere E-Mail mit deinem individuellen Angebot.
						</p>
						<p className="mt-4">
							3. Du bist einverstanden? Schließe den Buchungsprozess durch Onlinebezahlung ab.
						</p>
					</div>
				</div>

			</section>
		</Layout>
);
